$sk-font: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$border-radius: .5em;
$max-desktop-width: 1200px;
$sk-standard-ui-shadow: 0px 5px 10px 2px rgba(0,0,0,0.125);

// Sportkultour Colors
$light: rgba(242, 245, 244, 1);
$dark: rgba(66, 66, 66, 1);
$blue: rgba(30, 131, 165, 1);
$sk-dark-blue: darken($blue, 10%);
$blue-light: rgba(57, 169, 220, 1);
$sk-blue-lighter: rgba(86, 192, 215, 1);
$sk-green: rgba(56 ,169, 98, 1);
$sk-green-lighter: rgba(128, 198, 179);

