@use 'node_modules/@angular/material' as mat;
@use 'node_modules/@angular/material/theming' as mat-theming;
@use 'palette';
@use 'sk-mat-overrides' as override;
@use 'theme-vars' as sk;
@use 'fonts';
@use 'sk-classes';

$primary-palette: palette.createPalette(sk.$blue);
$accent-palette: palette.createPalette(sk.$blue);
$warn-palette: palette.createPalette(#bc2222);

$warn: mat.define-palette($warn-palette);
$primary: mat.define-palette($primary-palette);
$accent: mat.define-palette($accent-palette);

$sk-mat-typography: mat-theming.mat-typography-config(
  $font-family: sk.$sk-font,
  $headline: mat-theming.mat-typography-level(32px, 48px, 700),
  $body-1: mat-theming.mat-typography-level(16px, 24px, 500)
);

@include mat.core();

$sk-mat-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($primary),
    accent: mat.define-palette($accent),
    warn: mat.define-palette($warn),
  ),
  typography: $sk-mat-typography,
  density: 0,
));

@include mat.all-component-themes($sk-mat-theme);

html {
  overflow: auto;
  @include override.material-override();
}

html, body {
  display: block;
  font-family: sk.$sk-font;
  font-size: 100%;
  color: sk.$dark;
  background: darken(sk.$light, 1.25%);
  min-height: 100vh;
  max-width: 100vw;
}

a {
  text-decoration: none;
  font-weight: bold;
  transition: color .25s ease;
  &:link {
    color: sk.$blue;
  }
  &:visited {
    color: darken(sk.$blue, 5%);
  }
  &:hover {
    color: lighten(sk.$blue, 10%);
  }
  &:active {
    color: lighten(sk.$blue, 20%);
  }
}

markdown {
  $h1size: 1.8em;
  h1, h2, h3, h4, h5, h6 {
    color: sk.$blue;
    font-weight: 700;
    letter-spacing: 2px;
  }
  h1 {
    font-size: $h1size;
  }
  h2 {
    font-size: $h1size * 0.9;
  }
}
