@font-face {
  font-family: 'Public Sans';
  src: url(assets/fonts/PublicSans-VariableFont_wght.ttf) format("opentype");
}

@font-face {
  font-family: 'Source Code Pro';
  src: url(assets/fonts/SourceCodePro-VariableFont_wght.ttf) format("opentype");
}

@font-face {
  font-family: 'Source Sans 3';
  src: url(assets/fonts/SourceSans3-VariableFont_wght.ttf) format("opentype");
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/MaterialIcons-Regular.ttf'); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('assets/fonts/MaterialIcons-Regular.ttf') format('truetype')
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
