@use 'theme-vars' as sk;

@mixin sk-static-pages-host {
  display: block;
  max-width: sk.$max-desktop-width;
  width: 100%;
  margin: 0 auto;
}

.sk-static-page-container {
  max-width: sk.$max-desktop-width;
  background: lighten(sk.$light, 2%);
  box-shadow: sk.$sk-standard-ui-shadow;
  border-radius: sk.$border-radius;
  padding: 1em;
  margin: 1em 1em;

  h1 {
    size: .75em;
    font-weight: 700;
    color: sk.$blue;
    user-select: none;
    margin-bottom: .5em;
    letter-spacing: 2px;
  }

  p {
    &.sk-p-head {
      font-weight: 600;
      color: lighten(sk.$dark, 5%);
    }
  }

}

.sk-ui-page-container {
  width: 100%;
  &.desktop {
    max-width: 100vw;
  }
}

.sk-std-button {
  display: block;
  padding: .5em;
  font-weight: bold;
  color: sk.$light;
  border-radius: sk.$border-radius;
  //border: 1px solid $sk-blue;
  background: rgba(sk.$blue,1);
  transition: all .125s linear;
  user-select: none;
  text-align: center;
  &:hover {
    cursor: pointer;
    background: lighten(sk.$blue, 5%);
  }
}

.transition-opacity {
  transition: opacity ease-in-out .25s;
}

.cookie-fade-in {
  animation: cookie-fade-in .25s ease-in forwards;
  animation-delay: .5s;
  opacity: 0;
}

.cookie-spinner-fade-in {
  animation: cookie-fade-in .5s ease-in forwards;
  animation-delay: 1.5s;
  opacity: 0;
  transition: height .25s ease-in;
}

@keyframes cookie-fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes cookie-spinner-fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}
