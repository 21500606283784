@use 'sass:math';
@use 'node_modules/@angular/material/theming';
@use 'theme-vars' as sk;
@mixin box-shadow($color, $opacity) {
  -webkit-box-shadow: 0 0 15px 5px rgba($color, $opacity);
  box-shadow: 0 0 15px 5px rgba($color, $opacity);
}
@mixin material-override() {
  *[matRipple] {
    * {
      //background: lighten(rgba(sk.$sk-blue,.5), 30%) !important;
      //filter: blur(3px) !important;
    }
  }
  button[matsnackbaraction] {
    background: sk.$blue;
    color: sk.$light !important;
    margin-left: 10px !important;
  }
  button[mat-icon-button] {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  button[mat-flat-button] {
    //font-weight: 500 !important;
    letter-spacing: 1px !important;
    color: sk.$light !important;
    //border-radius: math.div(sk.$sk-border-radius, 1.5) !important;
    transition: background ease .25s, box-shadow ease .75s !important;
    @include box-shadow(sk.$blue, 0);
    &[color="primary"] {
      &:hover {
        background: lighten(sk.$blue, 10%);
      }
    }
    &.pill {
      height: 24px !important;
      font-size: .75em;
      font-weight: 800 !important;
      letter-spacing: 1px !important;
    }
  }
  .mat-menu-mobile {
    border: 1px solid red !important;
  }
  .cookie-diag-panel {
    width: 100%;
    border-radius: sk.$border-radius * 1.5;
    padding: 1em;
    &.mobile {
    }
    &.desktop {
    }
  }
  // OVERRIDES FOR DEV-BAR
  #dev-tools {
    mat-chip-option {
      button[aria-selected="true"] {
        span {
          color: sk.$light !important;
        }
      }
    }
  }
}

